import React from "react"
import { graphql, Link } from "gatsby"
import { FaAngleRight, FaAngleLeft } from "../../src/components/icons"
import Layout from "./layout.template"
import { Seo } from "../components/index"
import Markdown from "react-markdown"
import "./_pageGallery.scss"

const PageGalleryTemplate = ({ data }) => {
  const page = data?.strapiGalleryItems

  const prevImg = data?.allStrapiGalleryItems?.nodes.find(
    img => img.strapiId === page.strapiId + 1
  )
  const nextImg = data?.allStrapiGalleryItems?.nodes.find(
    img => img.strapiId === page.strapiId - 1
  )

  return (
    <Layout>
      <Seo title={page.title} />
      <div className="galleryItem container">
        <h3 className="galleryItem__title row">{page.title}</h3>
        <Link to={"/catalogo-de-arte"} className="galleryItem__homeLink row">
          Inicio
        </Link>
        <div className="row">
          <div className="col-12 col-sm-7">
            <img
              src={page.image.childImageSharp.original.src}
              alt={page.title}
              className="galleryItem__img"
            />
          </div>
          <div className="col-12 col-sm-5">
            <div className="markdown galleryItem__description">
              <Markdown escapeHtml={true}>{page.description}</Markdown>
            </div>
            {page.extraInfo && (
              <div className="markdown galleryItem__extra">
                <Markdown escapeHtml={true}>{page.extraInfo}</Markdown>
              </div>
            )}
          </div>
        </div>

        {(prevImg || nextImg) && (
          <div className="prevNext row">
            {prevImg && (
              <Link
                className="prevNext__link prev col-12 col-sm-6 mr-auto"
                to={`/catalogo-de-arte/${prevImg.slug}`}
              >
                <FaAngleLeft
                  size={35}
                  color={"#adb0b6"}
                  className="d-none d-sm-block"
                />
                <div className="prevNext__link-text">
                  <p>ANTERIOR</p>
                  <h6>{prevImg.title}</h6>
                </div>
              </Link>
            )}
            {nextImg && (
              <Link
                className="prevNext__link prevNext__link-next col-12 col-sm-6 ml-auto"
                to={`/catalogo-de-arte/${nextImg.slug}`}
              >
                <div className="prevNext__link-text">
                  <p>SUIGIENTE</p>
                  <h6>{nextImg.title}</h6>
                </div>
                <FaAngleRight
                  size={35}
                  color={"#adb0b6"}
                  className="d-none d-sm-block"
                />
              </Link>
            )}
          </div>
        )}
      </div>
    </Layout>
  )
}

export default PageGalleryTemplate

export const query = graphql`
  query PageGallery($slug: String!) {
    strapiGalleryItems(slug: { eq: $slug }) {
      title
      slug
      strapiId
      extraInfo
      description
      id
      image {
        childImageSharp {
          gatsbyImageData
          original {
            src
            height
            width
          }
        }
      }
    }
    allStrapiGalleryItems {
      nodes {
        strapiId
        title
        slug
      }
    }
  }
`

// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-catalogo-de-arte-js": () => import("./../../../src/pages/catalogo-de-arte.js" /* webpackChunkName: "component---src-pages-catalogo-de-arte-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-eventos-js": () => import("./../../../src/pages/eventos.js" /* webpackChunkName: "component---src-pages-eventos-js" */),
  "component---src-pages-identidad-21-js": () => import("./../../../src/pages/identidad21.js" /* webpackChunkName: "component---src-pages-identidad-21-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-noticias-js": () => import("./../../../src/pages/noticias.js" /* webpackChunkName: "component---src-pages-noticias-js" */),
  "component---src-pages-oferta-academica-filtro-js": () => import("./../../../src/pages/oferta-academica-filtro.js" /* webpackChunkName: "component---src-pages-oferta-academica-filtro-js" */),
  "component---src-pages-oferta-academica-js": () => import("./../../../src/pages/oferta-academica.js" /* webpackChunkName: "component---src-pages-oferta-academica-js" */),
  "component---src-pages-sedes-y-centros-js": () => import("./../../../src/pages/sedes-y-centros.js" /* webpackChunkName: "component---src-pages-sedes-y-centros-js" */),
  "component---src-templates-page-blog-js": () => import("./../../../src/templates/pageBlog.js" /* webpackChunkName: "component---src-templates-page-blog-js" */),
  "component---src-templates-page-career-js": () => import("./../../../src/templates/pageCareer.js" /* webpackChunkName: "component---src-templates-page-career-js" */),
  "component---src-templates-page-event-js": () => import("./../../../src/templates/pageEvent.js" /* webpackChunkName: "component---src-templates-page-event-js" */),
  "component---src-templates-page-gallery-js": () => import("./../../../src/templates/pageGallery.js" /* webpackChunkName: "component---src-templates-page-gallery-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-page-news-js": () => import("./../../../src/templates/pageNews.js" /* webpackChunkName: "component---src-templates-page-news-js" */),
  "component---src-templates-page-site-js": () => import("./../../../src/templates/pageSite.js" /* webpackChunkName: "component---src-templates-page-site-js" */)
}

